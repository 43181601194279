<template>
  <div class="select cursor-hover" @click="handleLang()">
    <div class="select__items">
      <div
        v-for="lang in langs"
        :key="lang.id"
        class="select__item cursor-hover"
      >
        {{ lang.label }}
      </div>
      <span class="decor cursor-hover" :class="{transformed: getCurrentLangs.id === 1}"></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'langs-select',
  data() {
    return {
      currentLangs: null,
      hoveringItem: false,
    };
  },
  computed: {
    ...mapGetters([
      'getUserTheme',
      'getCurrentLangs',
      'getLangs',
    ]),
    langs() {
      return this.$store.getters.getLangs;
    },
  },
  methods: {
    ...mapActions([
      'setCurrentPage',
    ]),
    handleLang() {
      let lang = null;

      if (this.getCurrentLangs && this.getCurrentLangs.id === 0) {
        lang = 1;
      }
      if (this.getCurrentLangs && this.getCurrentLangs.id === 1) {
        lang = 0;
      }

      // eslint-disable-next-line no-restricted-globals

      this.$store.dispatch('setCurrentLangs', lang);

      localStorage.setItem('langINCHKIEV', JSON.stringify(this.getCurrentLangs));

      location.reload();
    },
  },
  mounted() {
    this.setCurrentPage(this.$route.path.replace('/', ''));
  },
  created() {

    if (localStorage.getItem('langINCHKIEV')) {
      // this.currentLangs = JSON.parse(localStorage.getItem('langINCHKIEV'));
      // console.log(this.currentLangs);
      this.$store.dispatch('setCurrentLangs', JSON.parse(localStorage.getItem('langINCHKIEV')).id);
    } else {
      localStorage.setItem('langINCHKIEV', JSON.stringify(this.getCurrentLangs));
    }

    this.$i18n.locale = this.getCurrentLangs.lang;
  },
};
</script>

<style scoped lang="scss">
.select {
  border: 1px solid #92A1AE;
  border-radius: 83px;
  padding: 4px;
  width: 68px;
  height: 36px;
  position: relative;

  &__items {
    position: absolute;
    top: 0;
    left: -1px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2px;

    & > .decor {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: 2px;
      left: 4px;
      border-radius: 50%;
      border: 2px solid var(--footer-text-color);
      width: 30px;
      height: 30px;
      transition: all .3s ease;

      &.transformed {
        transform: translateX(30px);
      }
    }
  }

  // &__checkbox {
  //   width: 60px;
  //   height: 36px;
  //   opacity: 0;
  //   top: -8px;
  //   left: -4px;
  //   z-index: 5;
  //   position: relative;
  //   cursor: pointer;
  //   background-color: transparent;

  //   &:checked + .select__items::before {
  //     transform: translateX(30px);
  //   }
  // }

  &__item {
    padding: 4px;
    font-weight: 500;
    font-size: 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--footer-text-color);
    background-color: transparent;
    transition: all .3s ease;

    display: flex;
    justify-content: center;
    align-items: center;

    // &:hover {
    //   background-color: #29F6D9;
    // }
  }
}
</style>
