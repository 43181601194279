import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import scrollAnalytics from '@/components/scroll-analytics.vue';
import 'swiper/swiper-bundle.css';

const app = createApp(App);

app.component('scrollAnalytics', scrollAnalytics);

app
  .use(router)
  .use(store)
  .use(i18n)
// app.config.productionTip = false;

app.mount('#app');

