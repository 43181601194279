<template>
  <Transition>
    <button
      v-if="isButtonVisible"
      class="to-top-button"
      @click="goToTop"
      @mouseover="changeArrowColor"
      @mouseleave="changeArrowColor"
      @mousedown="changeArrowColor"
      @mouseup="changeArrowColor"
    >
    <svg class="to-top-button__arrow" width="70" height="70" viewBox="5 5 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M28.0009 29.287L28.0009 41.1943L33.3741 41.1943L33.3741 29.2849L37.6886 33.5995L41.488 29.8001L30.6885 19.0006L19.4161 30.2731L23.2154 34.0725L28.0009 29.287Z" :fill="arrowFillColor"/><path d="M28.0009 41.1943L27.5009 41.1943L27.5009 41.6943L28.0009 41.6943L28.0009 41.1943ZM28.0009 29.287L28.5009 29.287L28.5009 28.0799L27.6474 28.9334L28.0009 29.287ZM33.3741 41.1943L33.3741 41.6943L33.8741 41.6943L33.8741 41.1943L33.3741 41.1943ZM33.3741 29.2849L33.7276 28.9314L32.8741 28.0778L32.8741 29.2849L33.3741 29.2849ZM37.6886 33.5995L37.3351 33.953L37.6886 34.3066L38.0422 33.953L37.6886 33.5995ZM41.488 29.8001L41.8415 30.1537L42.1951 29.8001L41.8415 29.4465L41.488 29.8001ZM30.6885 19.0006L31.0421 18.6471L30.6885 18.2935L30.335 18.6471L30.6885 19.0006ZM19.4161 30.2731L19.0625 29.9196L18.709 30.2731L19.0625 30.6267L19.4161 30.2731ZM23.2154 34.0725L22.8619 34.426L23.2154 34.7796L23.569 34.426L23.2154 34.0725ZM28.5009 41.1943L28.5009 29.287L27.5009 29.287L27.5009 41.1943L28.5009 41.1943ZM33.3741 40.6943L28.0009 40.6943L28.0009 41.6943L33.3741 41.6943L33.3741 40.6943ZM32.8741 29.2849L32.8741 41.1943L33.8741 41.1943L33.8741 29.2849L32.8741 29.2849ZM38.0422 33.2459L33.7276 28.9314L33.0205 29.6385L37.3351 33.953L38.0422 33.2459ZM41.1344 29.4465L37.3351 33.2459L38.0422 33.953L41.8415 30.1537L41.1344 29.4465ZM30.335 19.3542L41.1344 30.1537L41.8415 29.4465L31.0421 18.6471L30.335 19.3542ZM19.7696 30.6267L31.0421 19.3542L30.335 18.6471L19.0625 29.9196L19.7696 30.6267ZM23.569 33.7189L19.7696 29.9196L19.0625 30.6267L22.8619 34.426L23.569 33.7189ZM27.6474 28.9334L22.8619 33.7189L23.569 34.426L28.3545 29.6405L27.6474 28.9334Z" fill="#354F57"/>
    </svg>

    </button>
  </Transition>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const userTheme = computed(() => store.getters.getUserTheme);

const arrowFillColor = ref('#29F6D9');
const isButtonVisible = ref(false);

function goToTop() {
  window.scrollTo({
    top: 0,
  });
}

function changeArrowColor(event) {
  if (event.type === 'mouseover') {
    arrowFillColor.value = userTheme.value === 'light-theme' ? '#fff' : '#000'
  }

  if (event.type === 'mouseleave') {
    arrowFillColor.value = '#29F6D9';
  }

  if (event.type === 'mousedown') {
    arrowFillColor.value = '#29F6D9';
  }

  if (event.type === 'mouseup') {
    arrowFillColor.value = userTheme.value === 'light-theme' ? '#fff' : '#000'
  }
}

function handleScroll() {
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  const scrolled = (winScroll / height) * 100;
  isButtonVisible.value = scrolled > 10;
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss">
.to-top-button {
  position: fixed;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2f2f2f;
  border-radius: 50%;
  right: 50px;
  bottom: 100px;
  z-index: 10000;
  transition: .2s;

  &:hover {
    border-color: transparent;
    background-color: #00E2C2;
  }

  @media screen and (max-width: 768px) {
    & {
      transform: scale(.8);
      right: 25px;
    }
  }

  @media screen and (max-width: 590px) {
    & {
      transform: scale(.6);
      right: 5px;
    }
  }

  &.v-enter-active, &.v-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }

  &.v-enter-from {
    opacity: 0;
    transform: scale(0.4);
  }

  &.v-enter-to {
    opacity: 1;
    transform: scale(1);

    @media screen and (max-width: 768px) {
      & {
        transform: scale(.8);
      }
    }

    @media screen and (max-width: 590px) {
      & {
        transform: scale(.6);
      }
    }
  }

  &.v-leave-from {
    opacity: 1;
  }

  &.v-leave-to {
    opacity: 0;
    transform: scale(0.4);
  }
}
</style>
