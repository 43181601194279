<template>
  <div data-view>
    <div class="home">
      <scrollAnalytics></scrollAnalytics>

      <Header />

      <div class="home__content">
        <Morghing/>
        <WhySection></WhySection>
        <div class="watch" id="structure"></div>
        <Structure :class="{ animated: visibleStage.includes('structure') }"></Structure>
        <div class="watch" id="partners"></div>
        <Partners :class="{ animated: visibleStage.includes('partners') }"></Partners>
        <div class="watch" id="expertise"></div>
        <Expertise :class="{ animated: visibleStage.includes('expertise') }"></Expertise>
        <div class="watch" id="clients"></div>
        <Clients :class="{ animated: visibleStage.includes('clients') }"></Clients>
        <div class="watch" id="wins" style="height: 100px;"></div>
        <Wins :class="{ animated: visibleStage.includes('wins') }"></Wins>
      </div>
      <img fetchpriority="high" class="home__gradients" src="@/assets/img/main-page/Ellipse-1.svg">

      <Footer />
    </div>

  </div>
</template>

<script setup>
import Header from '@/components/Header.vue';
import WhySection from '@/components/MainPage/WhySection.vue';
import Morghing from '@/components/MainPage/Morghing.vue';
import Structure from '@/components/MainPage/Structure.vue';
import Partners from '@/components/MainPage/Partners.vue';
import Expertise from '@/components/MainPage/Expertise.vue';
import Clients from '@/components/MainPage/Clients.vue';
import Wins from '@/components/MainPage/Wins.vue';
import Footer from '@/components/NewFooter.vue';

import { computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useHelpers } from '../hooks/helpers';

const store = useStore();

const { getVisibilityOfElement, animateTitle } = useHelpers();

const visibleStage = computed(() => store.getters.getVisibleStage);

onMounted(() => {
  setCurrentPage('home');

  document.addEventListener('scroll', animate);
});

onUnmounted(() => {
  document.removeEventListener('scroll', animate);
});

function setVisibleStage (stageName) {
  store.commit('setVisibleStage', stageName);
}

function setCurrentPage (page) {
  store.commit('setCurrentPage', page);
}

function animate() {
  if (getVisibilityOfElement('why-section') && !visibleStage.value.includes('why-section')) {
    setVisibleStage('why-section');
    // animateTitle('why-section', 1, 1);
  }

  if (getVisibilityOfElement('structure') && !visibleStage.value.includes('structure')) {
    setVisibleStage('structure');
    animateTitle('structure', 13, 30);
  }

  if (getVisibilityOfElement('partners') && !visibleStage.value.includes('partners')) {
    setVisibleStage('partners');
    animateTitle('partners', 20, 30);
  }

  if (getVisibilityOfElement('expertise') && !visibleStage.value.includes('expertise')) {
    setVisibleStage('expertise');
    animateTitle('expertise', 34, 30);
  }

  if (getVisibilityOfElement('clients') && !visibleStage.value.includes('clients')) {
    setVisibleStage('clients');
    animateTitle('clients', 64, 40);
  }

  if (getVisibilityOfElement('wins') && !visibleStage.value.includes('wins')) {
    setVisibleStage('wins');
    animateTitle('wins', 70, 60);
  }
}
</script>

<style lang="scss">
@import "@/assets/style/animation.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

#structure {
  height: 244px;
}

#partners {
  height: 180px;
}

#expertise {
  height: 180px;
}

#clients {
  height: 140px;
}

#wins {
  height: 260px;
}


.section {
  &__title {
    font-size: 60px;
    line-height: 64px;
    font-weight: 300;
    text-align: left;
    color: var(--text-secondary-color);
    position: relative;
    margin-bottom: 0;

    .text-wrapper {
      position: relative;
      display: inline-block;
      overflow: hidden;
      padding: 10px 0;
      animation-iteration-count: 1;

      &:nth-of-type(2) {
        padding-top: 0;
      }
    }

    .letter {
      font-size: 60px;
      line-height: 64px;
      display: inline-block;
      animation-iteration-count: 1;

      &.strong {
        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-size: 80px;
        color: var(--footer-text-color);
      }
    }
  }

  &__list {
    padding-left: 0;
  }

  &__item {
    text-align: left;

    &--title {
      color: var(--text-secondary-color);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 0;

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--decor-line-color);
        position: absolute;
        bottom: -4px;
        left: 0;
      }
    }

    &--text {
      margin-top: 12px;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 8px;
      color: var(--text-secondary-color);
    }

    &--description {
      letter-spacing: 0.08em;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 36px;
    }

    &--link {
      letter-spacing: 0.04em;
      color: var(--text-secondary-color);
      font-family: "TimesNewRoman-I";
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      position: relative;
      text-decoration: none;

      .underline {
        width: calc(95%);
        background-image: linear-gradient(transparent calc(100% - 1px), #29F6D9 1px);
        background-repeat: no-repeat;
        background-size: 95% 100%;
        transition: background-size 1s;
      }

      svg {
        transform: rotate(-45deg);
        transition: transform .2s ease;
        margin-left: 4px;
      }

      &.second {
        .underline {
          background-size: 98% 100%;
        }
      }

      &:hover {
        svg {
          transform: rotate(0deg)
        }

        .underline {
          background-size: 0% 100%;
        }
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // border: 2px solid #92A1AE;
    padding-left: 0;

    &--item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 162px;
      border: 2px solid #92A1AE;
      position: relative;
      padding: 0 20% 0 20%;
      background-color: transparent;

      &:hover {
        background-color: var(--background-item-color-hover);
      }
    }
  }

  &__subtitle {
    text-align: left;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: var(--footer-text-color);
  }

  &__description {
    font-weight: 300;
    font-size: 20px;
    line-height: 140%;
    color: var(--text-secondary-color);
    text-align: left;
    margin-bottom: 0;
  }
}

.home {
  position: relative;

  &__content {
    position: relative;
    z-index: 2;
  }

  &__gradients {
    position: fixed;
    top: -40%;
    left: -15%;
    max-width: 1000px;
    object-fit: contain;
    pointer-events: none;
    z-index: 0;
    border-radius: 50%;
    animation: rotateGradient1 20s infinite linear;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
  }
}

@media screen and (max-width: 960px) {
  .section {
    &__title {
      font-weight: 300;

      .text-wrapper {
        padding: 10px 0;
      }

      .letter {
        font-size: 48px;
        line-height: 52px;

        &.strong {
          font-size: 56px;
          // line-height: 60px;
        }
      }
    }

    &__subtitle {
      font-size: 22px;
    }

    &__description {
      font-size: 18px;
    }

    &__grid {
      &--item {
        height: 94px;
        // padding: 0 10% 0 10%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #structure {
    height: 119px;
  }
  #partners {
    height: 162px;
  }
  #expertise {
    height: 132px;
  }
  #clients {
    height: 140px;
  }
  #wins {
    height: 126px;
  }
  .section {
    &__title {
      font-weight: 300;
      font-size: 44px;
      line-height: 44px;
      // .text-wrapper {
      //   // padding-bottom: 0;
      // }
      .letter {
        font-size: 36px;
        line-height: 40px;

        &.strong {
          font-size: 44px;
          // line-height: 44px;
        }
      }
    }

    &__subtitle {
      font-size: 20px;
      line-height: 28px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
    }

    &__item {
      &--title {
        font-size: 12px;
        line-height: 16px;
      }

      &--text {
        line-height: 24px;
        font-size: 16px;
      }

      &--description {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #structure {
    height: 93px;
  }
  #partners {
    height: 78px;
  }
  #expertise {
    height: 53px;
  }
  #clients {
    height: 70px;
  }
  #wins {
    height: 70px;
  }
  .section {
    &__title {
      .text-wrapper {
        padding-bottom: 0;
      }
    }

    &__subtitle {
      font-size: 20px;
      line-height: 28px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

</style>
