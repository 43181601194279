import anime from 'animejs';

export function useHelpers() {
  function getVisibilityOfElement(elementId) {
    const target = document.getElementById(elementId);

    if (target) {
      const targetPosition = {
        top: window.pageYOffset + target.getBoundingClientRect().top,
        left: window.pageXOffset + target.getBoundingClientRect().left,
        right: window.pageXOffset + target.getBoundingClientRect().right,
        bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
      };
      const windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight,
      };

      return (
        targetPosition.bottom > windowPosition.top
        && targetPosition.top < windowPosition.bottom
        && targetPosition.right > windowPosition.left
        && targetPosition.left < windowPosition.right
      );
    }
  }

  function animateTitle(name, num1, num2) {
    const structure = document.querySelector(`.${name}`);
    const textWrapperAll = structure.querySelectorAll('.text-wrapper .letters');

    textWrapperAll.forEach((e, index) => {
      const textWrapper = e;

      if (!Number.isInteger(index / 2)) {
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter strong'>$&</span>");
      } else {
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
      }
    });

    anime({
      targets: '.text-wrapper .letter',
      translateY: ['1.2em', 0],
      translateZ: 0,
      duration: 750,
      count: 1,
      // eslint-disable-next-line consistent-return
      delay: (el, i) => {
        const ind = i - num1;
        return ind * num2;
      },
    });
  };

  return {
    getVisibilityOfElement,
    animateTitle,
  }
}
