<template>
  <div class="morghing">

    <div>
      <video v-if="videoPath !==null"
             :class=" [isMobile ? 'mob' : 'desk']"
             class="morghing__video"
             muted
             playsInline
             autoplay
             ref="videoRef"
      >
        <source type="video/mp4" :src="`${videoPath}`">
      </video>

    </div>

    <div class="arrow-rapper">
      <div @click="scrollToTheNextSection" class="arrow-block cursor-hover">
        <svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70.8086 38.8096L44.8181 64.8001L20.4727 40.4546" stroke="#354F57" stroke-width="2"/>
          <path d="M44.8184 64.7957V0.817383" stroke="#354F57" stroke-width="2"/>
        </svg>
      </div>
    </div>

  </div>
</template>

<script setup>
  import {
    computed,
    nextTick,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();

  const videoRef = ref(null);

  const isMobile = ref(window.innerWidth <= 768);
  const videoPath = ref(null);

  const userTheme = computed(() => store.getters.getUserTheme);
  const currentLanguage = computed(() => store.getters.getCurrentLangs);

  onMounted(() => {
    nextTick().then(() => {
      reloadVideo();
    });

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', handleResize);
    document.querySelector('.morghing').addEventListener('wheel', handleScroll);
  });

  watch([userTheme, isMobile], () => {
    reloadVideo();
  });

  function reloadVideo() {
    const device = isMobile.value ? 'mob' : 'desk';

    videoPath.value = `/video/${currentLanguage.value.lang}/${userTheme.value}/${device}/video-main.mp4`;

    if (videoRef.value) {
      videoRef.value.pause();
      videoRef.value.currentTime = 0;
      videoRef.value.load();
    }
  }

  function scrollToTheNextSection(e) {
    document.querySelector('#why-section').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  function handleResize() {
    isMobile.value = window.innerWidth <= 768;
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  function handleScroll(e) {
    if (e.deltaY > 0) {
      setTimeout(() => {
        scrollToTheNextSection();
      }, 50);
    }
  }

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
    document.querySelector('.morghing').removeEventListener('wheel', handleScroll);
  });
</script>

<style lang="scss" scoped>
.morghing {
  background-color: var(--background-color-primary);

  margin-top: 74px;

  min-height: calc((var(--vh, 1vh) * 100) - 74px);

  @media screen and (max-width: 768px) {
    min-height: calc((var(--vh, 1vh) * 100) - 68px);
  }

  @media screen and (max-width: 660px) {
    min-height: calc((var(--vh, 1vh) * 100) - 56px);
  }

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  .arrow-rapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    height: 100vh;
    height: calc((var(--vh, 1vh) * 100) - 74px);

    @media screen and (max-width: 768px) {
      height: calc((var(--vh, 1vh) * 100) - 68px);
    }

    @media screen and (max-width: 660px) {
      height: calc((var(--vh, 1vh) * 100) - 56px);
    }
  }

  .arrow-block {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 36px;
    margin: 0 auto;
    max-width: 60px;
    width: 100%;
    height: auto;
    display: block;

    svg {
      display: block;
      max-width: 100%;
      height: auto;
    }

    @media screen and (max-width: 1024px) {
      max-width: 50px;
      bottom: 20px;
    }

    @media screen and (max-width: 600px) {
      max-width: 40px;
      bottom: 10px;
    }

    path {
      stroke: var(--text-secondary-color);
    }
  }

  &__video {
    &.mob {
      width: 100vw;
      height: auto;
    }

    &.desk {
      width: 100vw;
      height: calc((var(--vh, 1vh) * 100) - 74px);

      @media screen and (max-width: 768px) {
        height: calc((var(--vh, 1vh) * 100) - 68px);
      }

      @media screen and (max-width: 660px) {
        height: calc((var(--vh, 1vh) * 100) - 56px);
      }
    }
  }
}
</style>
