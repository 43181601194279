<template>
  <section :class="`expertise section ${currentLanguage.lang}`">
    <Vue3Lottie
        :animationData="animationObject.animationData"
        class="expertise__media mob"
        id="expertise-animation-mob"
        ref="animationRefMob"
        :autoPlay="animationObject.autoplay"
        :loop="animationObject.loop"
      />
    <div class="expertise__top">
      <h2 class="expertise__title section__title iosTitle type1">
        <span class="text-wrapper">
          <span class="letters">
            {{ `${$t('mainPage.expertise.title[0]') + '&nbsp;'}` }}
          </span>
        </span>
        <br>
        <span class="text-wrapper second-part">
          <span class="letters">
            {{ $t('mainPage.expertise.title[1]') }}
          </span>
        </span>
      </h2>
    </div>
    <div class="expertise__content">
      <div class="expertise__left grow">
        <h4 class="section__subtitle expertise__subtitle">
          {{ $t('mainPage.expertise.subtitle[0]') }}
          <br>
          {{ $t('mainPage.expertise.subtitle[1]') }}
        </h4>
        <p class="section__description expertise__description">{{ $t('mainPage.expertise.text') }}</p>
      </div>
      <Vue3Lottie
          :animationData="animationObject.animationData"
          class="expertise__media desc"
          id="expertise-animation"
          ref="animationRefDesc"
          :autoPlay="animationObject.autoplay"
          :loop="animationObject.loop"
        />
    </div>
  </section>
</template>

<script setup>
import animationData from '@/assets/style/animation/expertise.json';
import { Vue3Lottie } from 'vue3-lottie';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useHelpers } from '../../hooks/helpers';

const store = useStore();

const { getVisibilityOfElement } = useHelpers();

const currentLanguage = computed(() => store.getters.getCurrentLangs);
const userTheme = computed(() => store.getters.getUserTheme);

const animationRefMob = ref(null);
const animationRefDesc = ref(null);
const animationObject = {
  animationData,
  loop: false,
  autoplay: false,
};

onMounted(() => {
  document.addEventListener('scroll', startAnimation);
});

function playAnimation() {
  if (getVisibilityOfElement('expertise-animation')) {
    animationRefDesc.value.play();
  }

  if (getVisibilityOfElement('expertise-animation-mob')) {
    animationRefMob.value.play();
  }
}

function startAnimation() {
  setTimeout(() => {
    if ((getVisibilityOfElement('expertise-animation') && animationRefDesc.value) || (getVisibilityOfElement('expertise-animation-mob') && animationRefMob.value)) {
      document.removeEventListener('scroll', startAnimation); // TODO: still need to make it better
      playAnimation();
    }
  }, 0);
}
</script>

<style lang="scss" scoped>
.expertise {
  display: flex;
  flex-direction: column;
  padding-left: 405px;
  padding-right: 400px;

  &__title {
    margin-left: 30%;

    .text-wrapper.second-part {
      margin-left: 22%;
    }
  }

  &__subtitle {
    margin-bottom: 28px;
  }

  &__media {
    &.mob {
      display: none;
    }

    &.desc {
      max-width: 500px;
    }
  }

  &__description {
    max-width: 90%;
  }

  &__content {
    display: flex;
    margin-top: 28px;
  }

  &__left {
    max-width: 50%;
  }
}

@media screen and (max-width: 1600px) {
  .expertise {
    padding-left: 165px;
    padding-right: 242px;
  }
}

@media screen and (max-width: 1200px) {
  .expertise {
    &__description {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 960px) {
  .expertise {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 768px) {
  .expertise {
    &__content {
      margin-top: 49px;
    }

    &__title {
      margin-left: 34%;
    }
  }
}

@media screen and (max-width: 705px) {
  .expertise {
    &__media {
      max-width: 70%;
      margin: 0 auto 27px auto;

      &.desc {
        display: none;
      }

      &.mob {
        display: block;
      }
    }

    &__title {
      text-align: left;
      margin-left: 0;

      .text-wrapper {

        &:first-child {
          padding-bottom: 0;
        }

        &:last-child {
          padding-bottom: 10px;
        }

        &.second-part {
          margin-left: 0;
        }
      }
    }

    &__content {
      margin-top: 24px;
    }

    &__subtitle {
      margin-bottom: 16px;
    }

    &__left {
      max-width: 100%;
    }

    &__item {
      &--text > br:first-of-type {
        display: none;
      }
    }
  }
}
</style>
