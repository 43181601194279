<template>
  <header class="header" :class="getCurrentLangs.lang" id="header">
    <a class="header__side left part cursor-hover" href="/">
      <img class="cursor-hover" src="@/assets/img/logo.svg" alt="">
    </a>
    <div class="header__title part">
      <p v-html="$t('header.title.begin')"></p>
      <!-- <p v-html="$t('header.title.middle')"></p> -->
      <!-- <p>{{ $t("header.title.end") }}</p> -->
      <div id="progressBar" class="progress"></div>
    </div>
    <div class="header__middle part">
      <ThemeToggle></ThemeToggle>
    </div>
    <div class="header__middle part">
      <LangsSelect lightBg="false"></LangsSelect>
    </div>
    <div class="header__side right part">
      <span class="decor" :class="{clicked: beforeOpenMenu}"></span>
      <div class="right__wrapper cursor-hover" @click="handleMenu()">
        <svg class="menu-icon cursor-hover" :class="{rotated: getOpenMenu}" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 14L27 14" stroke="black" stroke-linecap="square"/>
          <path d="M14 1L14 27" stroke="black" stroke-linecap="square"/>
        </svg>
        <p class="header__menu-text cursor-hover">{{ $t('header.menu') }}</p>
      </div>
    </div>
    <customMenu v-show="getOpenMenu" @handleOpeningMenu="handleOpeningMenu"></customMenu>
  </header>
</template>

<script>
import ThemeToggle from '@/components/ThemeToggle.vue';
import LangsSelect from '@/components/LangsSelect.vue';
import customMenu from '@/components/customMenu.vue';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
      beforeOpenMenu: false,
    };
  },
  components: {
    ThemeToggle,
    customMenu,
    LangsSelect,
  },
  computed: {
    ...mapGetters([
      'getOpenMenu',
      'getCurrentLangs'
    ]),
    getTheme() {
      return localStorage.getItem('user-theme');
    },
  },
  methods: {
    ...mapMutations([
      'setOpenMenu',
    ]),
    handleMenu() {
      this.beforeOpenMenu = true;
      this.setOpenMenu();
      setTimeout(() => {
        this.beforeOpenMenu = false;
      }, 1000);
    },
    handleOpeningMenu() {
      this.setOpenMenu();
      // setTimeout(() => {
      //   this.setOpenMenu();
      // }, 1000);
    },
    progressBarScroll() {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      // eslint-disable-next-line prefer-template
      document.getElementById('progressBar').style.width = scrolled + '%';
    },
  },
  mounted() {
    const parts = document.querySelectorAll('.part');
    parts.forEach((part, i) => {
      part.classList.add('visible');
      // eslint-disable-next-line no-param-reassign
      part.style.transitionDelay = `${i + 1}s`;
    });
    document.addEventListener('scroll', this.progressBarScroll);
  },
};

</script>

<style lang="scss" scoped>
.header {
  height: 88px;
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  position: fixed;
  top: 0;
  z-index: 6;
  background-color: var(--background-color-primary);
  display: grid;
  grid-template-columns: 390px 4fr 1fr 1fr 390px;

  .part {
    transition: opacity;
  }

  p {
    margin-bottom: 0;
  }

  &::after {
    content: '';
    // width: 100%;
    width: 0%;
    height: 2px;
    background-color: #92A1AE;
    position: absolute;
    bottom: -2px;
    left: 0;
    animation: borderWidth 1s ease-in forwards;
  }

  &__menu-text {
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background: blur(10px);
  }

  &__side {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--background-color-primary);

    &.left {
      background-color: #000000;
      border-right: 2px solid #92A1AE;
    }

    &.right {
      position: relative;
      background-color: var(--background-color-primary);
      border-left: 2px solid #92A1AE;
      .decor {
        width: 44px;
        height: 44px;
        background-color: #29F6D9;
        border-radius: 50%;
        position: absolute;
        left: -8px;
        top: -8px;
        opacity: 0;
        pointer-events: none;

        &.clicked {
          opacity: 1;
          z-index: 6;
          animation: bubbleGrowth 1s ease-out;
        }
      }

      .right__wrapper {
        display: flex;
        align-items: center;
        // cursor: pointer;
        position: relative;
        background-color: var(--background-color-primary);
        width: 100%;
        height: 100%;
        justify-content: center;
      }

      .menu-icon {
        margin-right: 8px;
        transition: all .1s ease-in;
        z-index: 1;
        position: relative;

        path {
          stroke: var(--text-primary-color);
        }

        &.rotated {
          transform: rotate(45deg);
        }
      }
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    // width: 50%;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    padding: 0 2px;

    :deep(p) {
      text-align: center;
      .health {
        font-style: italic;
        font-family: "TimesNewRoman-I";
        font-size: 17px;
      }
      .mark {
        font-weight: 400;
      }
    }

    // p {
    //   white-space: nowrap;
    //   margin-bottom: 0;

    //   &:nth-of-type(1) {
    //     font-weight: 300;
    //   }

    //   &:nth-of-type(2) {
    //     font-style: italic;
    //     font-family: "TimesNewRoman-I";
    //     font-size: 17px;
    //     // font-weight: 400;
    //   }

    //   &:nth-of-type(3) {
    //     font-weight: 400;
    //     // font-family: 'TimesNewRoman';
    //   }
    // }

    .progress {
      position: absolute;
      left: 0;
      bottom: 0px;
      z-index: 2;
      background: #29F6D9;;
      // width: 100%;
      height: 4px;
    }
  }

  &__middle {
    height: 100%;
    border-left: 2px solid #92A1AE;
    // border-right: 2px solid #92A1AE;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__middle:nth-child(3) {
    border-right-color: transparent;
  }
}

@media screen and (max-width: 1600px) {
  .header {
    height: 74px;
    grid-template-columns: 375px 2fr 1fr 1fr 375px;

    &__middle {
      &:nth-of-type(3) {
        min-width: 213px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .header {
    grid-template-columns: 340px 2fr 1fr 1fr 340px;

    &__middle {
      &:nth-of-type(3) {
        min-width: 190px;
      }
    }
  }
}

@media screen and (max-width: 1370px) {
  .header {
    grid-template-columns: 328px 2fr 1fr 1fr 328px;

    &__middle {
      &:nth-of-type(3) {
        min-width: unset;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    grid-template-columns: 280px 2fr 1fr 1fr 280px;

    &__menu-text::before {
      top: -14px;
      left: -44px;
    }
  }
}

@media screen and (max-width: 960px) {
  .header {

    &.ua {
      .header__title p:nth-of-type(1){
        white-space: pre-line;
      }
    }

    grid-template-columns: 149px 2fr 117px 117px 149px;

    &__side {
      &.left {

        border-right: 1px solid #92A1AE;

        img {
          max-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 68px;

    &__title {
      padding: 0 8px;
      font-size: 11px;

      & > p:nth-of-type(2) {
        font-size: 14px;
      }
    }

    &__middle {
      // width: 117px;
      border-left: 1px solid #92A1AE;
    }

    &__side {
      &.right {
        border-left: 1px solid #92A1AE;
      }
    }

    &::after {
      height: 1px;
      bottom: -1px;
    }
  }
}

@media screen and (max-width: 660px) {
  .header {
    height: 56px;
    grid-template-columns: repeat(2, 1fr);

    &__middle {
      width: 120px;
      min-width: 120px;
      display: none;
    }

    &__title {
      padding: 0 8px;
      display: none;
    }

    &__side.right {
      border-left: none;
    }
  }
}

@media screen and (max-width: 580px) {
  .header {
    height: 56px;
  }
}

@keyframes borderWidth {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes bubbleGrowth {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: scale(80);
    opacity: 0;
  }
}
</style>
